import {Fragment, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {Menu, Transition} from "@headlessui/react";

import PencilIcon from "@heroicons/react/outline/PencilIcon";
import DuplicateIcon from "@heroicons/react/outline/DuplicateIcon";
import ArrowCircleUp from "@heroicons/react/outline/ArrowCircleUpIcon";
import ArrowCircleDown from "@heroicons/react/outline/ArrowCircleDownIcon";
import XCircleIcon from "@heroicons/react/outline/XCircleIcon";
import ChevronDownIcon from "@heroicons/react/outline/ChevronDownIcon";

import {WebsiteContext} from '../index';

import {supportsField} from "./index";
import {backgroundColour} from "../utils/block-utils";

import AnimationOnScroll from '../components/animate-on-scroll';
import InlineAddBlockButton from "@autocx/forms/src/builder/inline-add-block-button";
import {nanoid} from "nanoid";

BlockActions.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    block: PropTypes.object.isRequired,
    onAction: PropTypes.func,
}

BlockActions.defaultProps = {
    onAction: () => {
    }
}

const BLOCK_ACTIONS = [
    {name: 'Edit', icon: PencilIcon},
    {name: 'Move Up', icon: ArrowCircleUp},
    {name: 'Move Down', icon: ArrowCircleDown},
    {name: 'Duplicate', icon: DuplicateIcon},
    {name: 'Delete', icon: XCircleIcon, type: 'destructive'},
];

function openNavigationSettings(id) {
    document.dispatchEvent(new CustomEvent('cx:open-navigation-settings', {
        bubbles: true,
        cancelable: true,
        detail: {navigation: id === 'footer' ? id : 'header'}
    }));
}

function BlockActions({className, onAction, style, block, ...props}) {
    const context = useContext(WebsiteContext);
    const actions = [...BLOCK_ACTIONS]
    const PrimaryIcon = actions[0].icon;
    
    if (!context.blockTemplatesLookup[block.id]) actions.splice(actions.length - 1, 0, {name: 'Make Template', icon: DuplicateIcon})

    return (
        <div id={`block_actions_${block.id}`} style={{...style, pointerEvents: "none"}}
             className={classNames(
                 "opacity-0 transition-opacity ease-in absolute transform z-[1001] right-4 top-0 block-actions h-full",
                 className,
             )}>
            <div className={"inline-flex sticky py-4 top-0 pointer-events-auto"}>
                <button
                    type="button"
                    onClick={() => {
                        onAction(actions[0], block);
                    }}
                    className="bg-black bg-opacity-70 backdrop-blur-md backdrop-filter shadow-lg text-white fill-current relative inline-flex border border-white items-center px-4 py-2 rounded-l-full text-sm font-medium focus:outline-none"
                >
                    <PrimaryIcon className="h-4 w-4 mr-1"/>
                    {actions[0].name}
                </button>

                <Menu as="span" className="-ml-px relative block">
                    <Menu.Button
                        className="bg-black bg-opacity-70 backdrop-blur-md backdrop-filter shadow-lg text-white fill-current relative inline-flex border border-white items-center px-3 py-2 rounded-r-full text-sm font-medium focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 -ml-0.5" aria-hidden="true"/>
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="border border-white origin-top-right absolute right-0 p-1 mt-1.5 mr-0.5 w-40 rounded-lg shadow-lg bg-black bg-opacity-70 backdrop-blur-md backdrop-filter shadow-lg text-white fill-current focus:outline-none"
                            style={{zIndex: 50}}>
                            <div className="py-1">
                                {actions.slice(1).map(({icon: Icon, ...item}) => (
                                    <Menu.Item key={item.name}>
                                        {({active}) => (
                                            <button
                                                type={"button"}
                                                className={classNames(
                                                    'flex justify-between items-center w-full px-2 py-2',
                                                    'text-sm font-medium rounded-md hover:bg-black',
                                                    item.type === 'destructive' ? 'text-red-500' : 'text-current'
                                                )}
                                                onClick={() => onAction(item, block)}
                                            >
                                                {item.name}
                                                <Icon className="h-4 w-4 ml-1"/>
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    )
}

function NavigationActions({className, onAction, style, ...props}) {
    const PrimaryIcon = BLOCK_ACTIONS[0].icon;

    return (
        <div id={`block_actions_navigation`} style={{...style, pointerEvents: "none"}}
             className={classNames(
                 "opacity-0 transition-opacity ease-in absolute transform z-[1001] right-4 top-0 block-actions h-full",
                 className,
             )}>
            <div className={"inline-flex sticky py-4 top-0 pointer-events-auto"}>
                <button
                    type="button"
                    onClick={() => {
                        openNavigationSettings(props.block.id)
                    }}
                    className="bg-black bg-opacity-70 backdrop-blur-md backdrop-filter shadow-lg text-white fill-current relative inline-flex border border-white items-center px-4 py-2 rounded-full text-sm font-medium focus:outline-none"
                >
                    <PrimaryIcon className="h-4 w-4 mr-1"/>
                    {BLOCK_ACTIONS[0].name}
                </button>

            </div>
        </div>
    )
}

Block.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    actions: PropTypes.bool,
    onAction: PropTypes.func,
    onSelect: PropTypes.func,
    selectedBlock: PropTypes.string,
}

Block.defaultProps = {
    actions: false
}

function BlockDivider({nextBlock, block}) {
    return (
        <div
            className={!nextBlock?.values?.options?.inset ? backgroundColour(nextBlock?.values?.options?.backgroundColour) + '-svg-fill' : null}
            dangerouslySetInnerHTML={{__html: block.values?.options?.blockDivider}}
            style={{overflow: "hidden"}}
        />
    )
}

function AnimationWrapper(props) {
    const prefersReducedMotionQuery = typeof window !== "undefined" && window.matchMedia('(prefers-reduced-motion: reduce)');
    const reduceMotion = prefersReducedMotionQuery.matches === true;

    /*
        Return the Block without the Animation wrapper if the user has reduce motion turned on in their system settings,
        if no animation setting is in their theme context or if we're looking at a page through the builder. 
        i.e. The wrapper should only appear if an animation style is chosen and we're in the Design tab or on the preview/live site.
    */
    if (props.isVisible || reduceMotion || props.animationStyle === 0 || props.animationStyle === undefined || (!props.isDesign && props.isBuilder) || props.isPicker || props.children.props.children.type === 'header') {
        return props.children
    } else {
        return (
            <AnimationOnScroll
                animateIn={props.animationStyle}
                duration={props.duration}
                scrollableParentSelector={props.isBuilder ? "#centre_scroll_container" : null}
                key={`${props.blockId}_${props.animationStyle}_${props.duration}`}
                animateOnce={true}
                className={"relative"}
            >
                {props.children}
            </AnimationOnScroll>
        )
    }
}

function BuilderBlockContainer(props) {
    const context = useContext(WebsiteContext);
    const hideInlineAddBlockButtons = props.hideInlineAddBlockButton || context.isEditing;
    const handleOnClick = (index) => {
        index = index < 0 ? 0 : index;
        document.dispatchEvent(new CustomEvent('cx:on-component-add', {
            bubbles: true,
            cancelable: true,
            detail: {
                component: {
                    id: nanoid(),
                    type: 'placeholder-block',
                    placeholder: true,
                    values: {}
                },
                index
            }
        }));
    }
    /*
    Only show the builder block container when in the editor
    */
    if (!props.isDesign && !props.isBuilder) {
        return props.children
    } else {
        return (
            <div className={"relative block-builder-container"}>
                {!hideInlineAddBlockButtons ? (
                    <InlineAddBlockButton
                        key={`minus_${context.isEditing}`}
                        index={props.index}
                        className={"invisible absolute top-[-10px]"}
                        label={"Add Block"}
                        onClick={() => handleOnClick(props.index)}
                    />
                ) : null}
                {props.children}
                {!hideInlineAddBlockButtons ? (
                    <InlineAddBlockButton
                        key={`plus_${context.isEditing}`}
                        className={"invisible absolute bottom-[-8px]"}
                        label={"Add Block"}
                        onClick={() => handleOnClick(props.index + 1)}

                    />
                ) : null}
            </div>
        )
    }
}

const hideOn = (value) => {
    switch (value) {
        case 'mobile':
            return 'hidden sm:block';
        case 'desktop':
            return 'sm:hidden block';
        case 'both':
            return 'hidden';
        default:
            return ''
    }
}

export default function Block({
                                  style,
                                  className,
                                  children,
                                  actions,
                                  onAction,
                                  onSelect,
                                  selectedBlock,
                                  index,
                                  hideInlineAddBlockButton,
                                  as = 'section',
                                  ...block
                              }) {
    const context = useContext(WebsiteContext);
    const blockRef = useRef();
    const BlockComponent = as;
    const isDesign = typeof window !== "undefined" && window.location.href.includes("/design")
    const isBuilder = context.builder;
    const [duration, setDuration] = useState(0)

    if (!isBuilder && block.hideOn === 'both') return null;

    useEffect(() => {
        if (blockRef.current) {
            Array.from(blockRef.current?.children)
                .forEach((child) => {
                    child.classList.toggle('select-none', context.picker === true);
                    child.classList.toggle('pointer-events-none', context.picker === true);
                });
        }
    }, [blockRef.current, context.picker]);

    useEffect(() => {
        const containerHeight = isBuilder ? document.getElementById('centre_scroll_container').offsetHeight : window.innerHeight;
        if (blockRef.current?.getBoundingClientRect().top > containerHeight) {
            setDuration(1);
        }
    }, []);

    return (
        <AnimationWrapper isDesign={isDesign} isBuilder={isBuilder} isPicker={context.picker} duration={duration}
                          blockId={block.id}
                          animationStyle={context.theme.selectedBlockAnimation}>
            <BuilderBlockContainer isDesign={isDesign} isBuilder={isBuilder} index={index}
                                   hideInlineAddBlockButton={!actions || hideInlineAddBlockButton}>
                <BlockComponent
                    id={`block_${block.id}`}
                    ref={blockRef}
                    style={style}
                    className={classNames(
                        "break-words",
                        block.values?.options?.inset ? 'inset-block' : null,
                        block.websiteContext?.rootPage?.nodeType === 'blog' ? 'sm:rounded-theme rounded-none' : null,
                        actions ? 'actions' : null,
                        block.values?.design === undefined || block.values?.design === null ? null : `${block.type}-${block.values?.design ?? 0}`,
                        onSelect ? "cursor-pointer" : null,
                        isBuilder ? 'z-0' : null,
                        selectedBlock === block.id ? "selected" : null,
                        block.classNames,
                        className,
                        !isBuilder && block.hideBlock ? hideOn(block.hideOn) : null
                    )}
                    onClick={onSelect ? (e => onSelect(e, block)) : null}
                    onDoubleClick={() => {
                        if (isBuilder) {
                            if (block.id === "navigation" || block.id === "footer") {
                                openNavigationSettings(block.id)
                            } else {
                                onAction(BLOCK_ACTIONS[0], block);
                                let sel = window.getSelection();
                                sel.removeAllRanges();
                            }
                        }
                    }}
                >
                    {actions && (block.id === 'navigation' || block.id === 'footer') &&
                        <NavigationActions block={block}/>}
                    {actions && !(block.id === 'navigation' || block.id === 'footer') &&
                        <BlockActions block={block} onAction={onAction}/>}
                    {children}
                    {supportsField(block, 'options.blockDivider') &&
                        <BlockDivider block={block} nextBlock={block.nextBlock}/>
                    }
                </BlockComponent>
            </BuilderBlockContainer>
        </AnimationWrapper>
    )
}