import Design1 from './design-1';
import Design2 from './design-2';
import Design3 from './design-3';
import Design4 from './design-4';
import Design5 from './design-5';

import './index.css';
import useApplyUserDefinedListOverrides from "../../hooks/use-user-defined-list-sort";
import {WebsiteContext} from "../../index";
import {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import {makeItems} from "../../utils/utils";
import {Dialog, Transition} from "@headlessui/react";

export default function ListOfItemsBlock(props) {
    const context = useContext(WebsiteContext);
    const [items, setItems] = useState(props?.values?.items || []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({label: "", text: ""});

    const handleOpenModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const clearModalContent = () => {
        setModalContent({label: "", text: ""});
    };

    let DesignBlock;

    const values = useApplyUserDefinedListOverrides({
        ...props,
        values: {
            ...props?.values,
            items: props?.values?.source === 'collection' ? items : props.values.items
        }
    })

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (context.builder && props?.values?.source === 'collection') {
            axios
                .get(`/api/v2/channels/${context.channel}/nodes/${props.values.schema}/`, {cancelToken: cancelToken.token})
                .then(({data}) => {
                    const children = data?.[0]?.children;
                    setItems(makeItems(children, props?.values?.item));
                })
                .catch(err => {
                    if (!axios.isCancel(err)) console.log(err);
                })
        }
        return () => cancelToken.cancel();
    }, [props?.values]);

    switch (props.values?.design) {
        case 0 :
            DesignBlock = Design1;
            break;
        case 1:
            DesignBlock = Design2;
            break;
        case 2:
            DesignBlock = Design3;
            break;
        case 3:
            DesignBlock = Design4;
            break;
        case 4:
            DesignBlock = Design5;
            break;
        default:
            DesignBlock = Design1;
    }

    return (
        <>
            <DesignBlock {...props} values={values} handleOpenModal={handleOpenModal}/>
            <Transition appear show={isModalOpen} as={Fragment}
                        afterLeave={clearModalContent}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={handleCloseModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30"/>
                    </Transition.Child>
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="relative">
                                <Dialog.Panel
                                    className="bg-white rounded-theme shadow-lg max-w-lg w-full mx-auto p-6 max-h-[75vh] mb-[calc(var(--site-nav-height)-16px)] overflow-y-auto">
                                    <Dialog.Title
                                        as="h5"
                                        className="text-center text-gray-900"
                                    >
                                        {modalContent.label}
                                    </Dialog.Title>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: modalContent.text,
                                        }}
                                        className="rich-text"
                                    />
                                </Dialog.Panel>
                                <button
                                    onClick={handleCloseModal}
                                    className="absolute -bottom-1 mt-4 mx-auto left-1/2 transform -translate-x-1/2 border-2 border-gray-300 bg-white rounded-full shadow-lg overflow-hidden"
                                >
                                    <svg
                                        className="h-8 w-8 stroke-gray-800 bg-gray-100 p-1"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}