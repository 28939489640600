import Textarea from "./textarea";
import {memo, useEffect, useRef, useState} from "react";
import classNames from 'classnames';

const RichText = memo((props) => {
    const [loading, setLoading] = useState(true);
    const ref = useRef();
    const inputRef = useRef();
    const editorRef = useRef();

    function setDataExternally(e) {
        editorRef?.current?.setData(`<p>${e.detail.data}</p>`)
    }

    useEffect(() => {
        if (!editorRef.current) return;

        if (props.disabled) {
            editorRef.current.enableReadOnlyMode('form-spec');
        } else {
            editorRef.current.disableReadOnlyMode('form-spec');
        }
    }, [editorRef.current, props.disabled]);

    useEffect(() => {
        ClassicEditor
            .create(ref.current, {
                removePlugins: ['FileUpload', 'EmailPreview', 'Outdent', 'Indent'],
                link: {
                    defaultProtocol: 'https://'
                },
                fontColor: {
                    documentColors: 0,
                },
                heading: {
                    options: [
                        {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                        {model: 'heading1', view: 'h4', title: 'Heading 1'},
                        {model: 'heading2', view: 'h5', title: 'Heading 2'},
                    ]
                },
                toolbar: {
                        items: [
                            {
                                label: 'Basic styles',
                                icon: 'bold',
                                items: ['bold', 'italic', 'underline', 'strikethrough']
                            },
                            'paragraph',
                            'heading1',
                            'heading2',
                            'fontSize',
                            'link',
                            'blockQuote',
                            {
                                label: 'Lists',
                                icon: '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 5.75c0 .414.336.75.75.75h9.5a.75.75 0 1 0 0-1.5h-9.5a.75.75 0 0 0-.75.75zm-6 0C1 4.784 1.777 4 2.75 4c.966 0 1.75.777 1.75 1.75 0 .966-.777 1.75-1.75 1.75C1.784 7.5 1 6.723 1 5.75zm6 9c0 .414.336.75.75.75h9.5a.75.75 0 1 0 0-1.5h-9.5a.75.75 0 0 0-.75.75zm-6 0c0-.966.777-1.75 1.75-1.75.966 0 1.75.777 1.75 1.75 0 .966-.777 1.75-1.75 1.75-.966 0-1.75-.777-1.75-1.75z"></path></svg>\'',
                                items: ['bulletedList', 'numberedList']
                            },
                            'fontColor',
                            {
                                label: 'Superscript and Subscript',
                                icon: '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.677 8.678h2.549c.254 0 .447.05.58.152a.49.49 0 0 1 .201.413.54.54 0 0 1-.159.393c-.105.108-.266.162-.48.162h-3.594c-.245 0-.435-.066-.572-.197a.621.621 0 0 1-.205-.463c0-.114.044-.265.132-.453a1.62 1.62 0 0 1 .288-.444c.433-.436.824-.81 1.172-1.122.348-.312.597-.517.747-.615.267-.183.49-.368.667-.553.177-.185.312-.375.405-.57.093-.194.139-.384.139-.57a1.008 1.008 0 0 0-.554-.917 1.197 1.197 0 0 0-.56-.133c-.426 0-.761.182-1.005.546a2.332 2.332 0 0 0-.164.39 1.609 1.609 0 0 1-.258.488c-.096.114-.237.17-.423.17a.558.558 0 0 1-.405-.156.568.568 0 0 1-.161-.427c0-.218.05-.446.151-.683.101-.238.252-.453.452-.646s.454-.349.762-.467a2.998 2.998 0 0 1 1.081-.178c.498 0 .923.076 1.274.228a1.916 1.916 0 0 1 1.004 1.032 1.984 1.984 0 0 1-.156 1.794c-.2.32-.405.572-.613.754-.208.182-.558.468-1.048.857-.49.39-.826.691-1.008.906a2.703 2.703 0 0 0-.24.309zM7.03 10.349l3.818-3.819a.8.8 0 1 1 1.132 1.132L8.16 11.48l3.819 3.818a.8.8 0 1 1-1.132 1.132L7.03 12.61l-3.818 3.82a.8.8 0 1 1-1.132-1.132L5.9 11.48 2.08 7.662A.8.8 0 1 1 3.212 6.53l3.818 3.82z"></path></svg>\'',
                                items: ['superscript', 'subscript']
                            },
                            '|',
                            'insertTable',
                            '|',
                            'removeFormat',
                            'undo',
                            'redo',
                        ],
                        shouldNotGroupWhenFull: true
                    },
            })
            .then((editor) => {
                // Set the initial state of the editor
                editor.setData(props.value);

                // Handle disabled state
                if (props.disabled) {
                    editor.enableReadOnlyMode('form-spec');
                }

                // Handle when the editor has changed to programatically tell react what the value is. This code 
                // simulates what would happen if the client just typed into a textarea input
                editor.model.document.on('change', () => {
                    const input = inputRef.current
                    const lastValue = inputRef.current.value || null;
                    input.value = editor.getData();

                    const event = new Event("input", {bubbles: true});
                    const tracker = input._valueTracker;
                    if (tracker) {
                        tracker.setValue(lastValue);
                    }
                    input.dispatchEvent(event);
                });

                // Set a reference to the editor
                editorRef.current = editor;
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false);
            });

        document.addEventListener('cx:update-richtext-externally', setDataExternally)
        return () => document.removeEventListener('cx:update-richtext-externally', setDataExternally);

    }, []);

    return (
        <div className={classNames(
            "rich-text rounded",
            props.disabled ? "pointer-events-none" : null,
            !props.disabled ? "select-auto" : null,
            props.modified ? 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400' : null
        )}>
            <input
                id={props.id}
                ref={inputRef}
                type="textarea"
                name={props.name}
                className={"hidden"}
                value={props.value}
                onChange={e => {
                    props.onChange(e, props.name, e.target.value);
                }}
                disabled={props.disabled}
                required={props.required}
                data-error-message={props.errorMessage}
            />
            {loading ? (
                <div className={"flex w-full justify-center h-[183px]"}>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-500"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                </div>
            ) : null}

            <textarea ref={ref} className={"hidden"}/>
        </div>
    );
});

RichText.propTypes = Textarea.propTypes;

RichText.defaultProps = Textarea.defaultProps;

RichText.DisplayValue = function (props) {
    return props.value === undefined || props.value === null ? '\u2014' : (
        <div dangerouslySetInnerHTML={{__html: props.value}}/>
    );
}

export default RichText;