import Block from '../block'
import {
    aspectRatio,
    backgroundColour,
    backgroundPattern,
    frameType,
    objectFit,
    setTitleDecorationByClass,
    setTitleDecorationByElement,
    textAlignment
} from "../../utils/block-utils";
import classNames from "classnames";
import Action from "../../components/action";
import {Image} from "../../components/image";

const Container = (props) => {
    return (
        <div className={classNames(
            props.className,
            !props.values.options?.inset ? backgroundPattern(props) : null,
            (!props.values.options?.inset && !props.values.alternateBackgroundColours) ? backgroundColour(props.values.options?.backgroundColour) : null)
        }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                !props.values.alternateBackgroundColours ? backgroundColour(props.values.options?.backgroundColour) : null,
                backgroundPattern(props),
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props) : null,
                (props.values.options?.inset && !props.values.alternateBackgroundColours) ? backgroundColour(props.values.options?.backgroundColour) : null
            )}>
                {props.children}
            </div>
        </div>
    )
}


export default function Design5(props) {
    console.log(props);
    return (
        <Block {...props} className={props.className}>
            <Container {...props}>
                <div className={"w-full"}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                </div>
            </Container>

            <div className={classNames("relative w-full overflow-x-auto")}>
                <ul className={classNames(
                    "sm:space-x-4 mb-3 lg:space-x-0 lg:grid lg:gap-x-4")}>
                    {props.values.items?.map((item, i) => {
                        return (
                            <li key={i} className={classNames(
                                (props.values.alternateBackgroundColours && i % 2 === 0) ? backgroundColour(props.values.options?.firstBackgroundColour) : backgroundColour(props.values.options?.secondBackgroundColour),
                            )}>
                                <Container {...props}>
                                    <div
                                        className="group relative space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:gap-4">
                                        {item.image && (
                                            <div className={classNames(
                                                i % 2 === 0 ? null : 'order-1',
                                                "overflow-hidden sm:row-span-2 relative rounded-theme",
                                                aspectRatio(props),
                                                frameType(props) === 'circle' ? 'clip-circle' : '',
                                                frameType(props) === 'slant' ? 'clip-slant !rounded-none' : ''
                                            )}>
                                                <Image
                                                    className={classNames(
                                                        "lazyload lazymotion w-full h-full object-center",
                                                        objectFit(props.values.options?.imagePresentation),
                                                        item.action && item.action.type && !['button', 'buttonFull', 'link'].includes(props.values?.options?.actionStyle) ? 'group-hover:opacity-75' : '',
                                                        frameType(props) === 'slant' ? '!rounded-none' : ''
                                                    )}
                                                    id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                    src={item.imageState?.__cx__?.key || item.image}
                                                    key={item.image + item.imageState + props.values?.options?.imagePresentation}
                                                    alt={item?.altText}
                                                />
                                            </div>
                                        )}
                                        <div className="sm:col-span-1">
                                            <div className={classNames(textAlignment(props))}>
                                                <h5>
                                                    {(!props.values?.options?.actionStyle || props.values?.options?.actionStyle === 'noStyle') &&
                                                        <Action {...item}
                                                                action={{...item.action, label: undefined}}
                                                                itemActionStyle={props.values?.options?.actionStyle}
                                                                title={null}
                                                                button={false}
                                                                defaultHref={'#'}>
                                                            <span className="absolute inset-0"/>
                                                        </Action>
                                                    }
                                                    {item.title}
                                                </h5>
                                                {item.text ? (
                                                    <div dangerouslySetInnerHTML={{__html: item.text}}
                                                         className={"mt-1 rich-text"}/>
                                                ) : null}
                                                {props.values?.options?.actionStyle !== 'noStyle' && props.values?.options?.actionStyle &&
                                                    <div className={"mt-auto"}>
                                                        <Action className={"mt-3 mb-2"} {...item}
                                                                itemActionStyle={props.values?.options?.actionStyle}
                                                                title={null}/>
                                                    </div>
                                                }
                                                {item?.detailsPopup &&
                                                    <div className={"mt-auto pt-4"}>
                                                        <button className={"opacity-80 text-sm hover:opacity-100"}
                                                                onClick={() =>
                                                                    props.handleOpenModal(
                                                                        {
                                                                            label: item.detailsPopup.label,
                                                                            text: item.detailsPopup.text
                                                                        }
                                                                    )
                                                                }
                                                        >
                                                            {item?.detailsPopup?.label}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </li>
                        )
                    })}
                </ul>
            </div>
            
            {props.values.showingActionButton &&
                <Container {...props}>
                    <Action {...props.values} title={null}/>
                </Container>
            }
        </Block>
    )
}
