import '@autocx/websites/polyfills';

import Website from "@autocx/websites";

/**
 * Webpack: Entry point to build the React application
 */

export default function App(props) {
    return (
        <Website
            {...props.context}
            policies={props.website.policies}
            currency={props.website.workspaceCurrency}
            header={props.page.navigation.header}
            footer={props.page.navigation.footer}
            unlinked={props.page.navigation.unlinked}
            productCategories={props.page.navigation.productCategories}
            schema={props.page.navigation.schema}
            theme={props.website.theme}
            blocks={props.page.components}
            website={props.website}
        />
    );
};